// extracted by mini-css-extract-plugin
export var buttonNotChangingAppearance = "global-module--button-not-changing-appearance--7I1wy";
export var content = "global-module--content--PQTnJ";
export var genericButton = "global-module--generic-button--7g9er";
export var gradientBackgroundWithShadow = "global-module--gradient-background-with-shadow--tEEli";
export var layout = "global-module--layout--RIesQ";
export var simpleSeparator = "global-module--simple-separator--qPSRW";
export var tabSeparator = "global-module--tab-separator--yksI2";
export var tabSeparatorHigher = "global-module--tab-separator--higher--Td0St";
export var tabSeparatorLower = "global-module--tab-separator--lower--2wwdd";
export var tabSeparatorMedium = "global-module--tab-separator--medium--OdNdO";
export var tabSeparatorMediumHigher = "global-module--tab-separator--medium-higher--iKEKQ";
export var tabSeparator__line = "global-module--tab-separator__line--awz-K";
export var tabSeparator__tab = "global-module--tab-separator__tab--vmsQd";